import { IAccommodation } from "src/app/ui-partial/accommodations/accommodations.component";
import * as DBT from "../db-types";
import * as moment from 'moment-timezone';

export interface ITestSession {
    id?                   : DBT.ID,
    instit_group_id       : DBT.ID,
    test_session_setup_id : DBT.ID,
    test_window_id        : DBT.ID,
    delivery_format       : DBT.VARCHAR,
    room                  : DBT.VARCHAR,
    campus_building       : DBT.VARCHAR,
    address               : DBT.VARCHAR,
    city                  : DBT.VARCHAR,
    province              : DBT.VARCHAR,
    postal_code           : DBT.VARCHAR,
    phone_number          : DBT.VARCHAR,
    date_time_start       : DBT.DATETIME,
    closed_on             : DBT.DATETIME,
    cancelled_on          : DBT.DATETIME,
    capacity              : DBT.INT,
    is_access_code_enabled: DBT.BOOL_INT,
    access_code           : DBT.VARCHAR,
    is_hidden             : DBT.BOOL_INT,
    status                : DBT.VARCHAR,
    invitation_code       : DBT.VARCHAR,
    ////
    isTestDay:boolean,
    custom_booking_buffer_d?: DBT.INT,
  }

  export enum EDeliveryOption {
    COMPUTER_LAB = 'computer-lab',
    BYOD = 'byod',
    REMOTE = 'remote',
}
  export interface ITestSessionDashboardInfo extends ITestSession{
    invigLang: string;
    uid : DBT.ID,
      test_session_group_id: number;
    first_name : DBT.VARCHAR,
    last_name : DBT.VARCHAR,
    contact_email : DBT.VARCHAR,
    test_window_title : DBT.VARCHAR,
    bookings : number,
    waitlist : number,
    bookings_list : ITestSessionBookingInfo[],
    waitlist_list : ITestSessionBookingInfo[],
    time_ext_m : number,
    test_window_info: {
      duration_m ?: number,
      test_centre_name_slug ?: string,
      classroom_name_slug ?: string,
      remote_name_slug ?: string,
      is_invig_taketest ?: number
    },
    is_paused : DBT.BOOL_INT,
    is_closed : DBT.BOOL_INT,
    is_cancelled : DBT.BOOL_INT,
    videostream_link?: string,
    videostream_password?: string,
    is_mobile_tether: DBT.BOOL_INT,
    is_video_conference: DBT.BOOL_INT,
    bookingBuffer?:number,
    cancellingBuffer?:number
  }
  export interface ITestSessionBookingInfo {
    id?: DBT.INT,
    first_name? : DBT.VARCHAR,
    last_name? : DBT.VARCHAR,
    contact_email? : DBT.VARCHAR,
    lang_req?:string, 
    created_on? : DBT.DATETIME,
    uid?: number,
    reqTransferTimestamp?: moment.Moment,
    booking?: {
      bookings_list, 
      waitlist_list,
    },
    testSessionInfo?: {
      test_session_id?: number,
      delivery_format: string,
      room: string,
      campus_building: string,
      date_time_start: DBT.DATETIME,
      videostream_link: string
    },
    invigilatorInfo?: {
      first_name: string;
      last_name: string;
      contact_email: string;
    },
    response?:{
      id:number,
      created_on: DBT.DATETIME
      is_resolved?: number,
    },
    upload?:{
      url:string,
      caption: string,
      filePath: string,
    },
    loa_uploaded_on?:DBT.DATETIME,
    instit_name?:string,
    additional_accommodations?:IAccommodation[],
  }

  export interface ITestSessionRes extends ITestSession{
    location_id          ?: DBT.ID,
    session_num          ?: DBT.ID,
}
